<template>
  <a-modal
    title="新增"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 提现方式 -->
            <a-form-item label="服务名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- logo -->
            <a-form-item has-feedback label="logo" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <div style="width: 104px; height: 104px">
                  <a-upload 
                    style="margin: auto" 
                    name="avatar" 
                    accept=".png,.jpg,jpeg,.gif"
                    list-type="picture-card" 
                    :show-upload-list="false"
                    :customRequest="customUserRequest"
                    v-decorator="['picture', { rules: [{ required: true, message: '请上传！' }] }]"
                  >
                    <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                    <div v-else>
                      <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                    </div>
                  </a-upload>
              </div>
            </a-form-item>
            <!-- 排序 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number 
                placeholder="请输入"
                :min="1"
                :max="1000"
                style="width: 40%;min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
            <!-- 消息内容 -->
            <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-textarea
                v-decorator="['content', { rules: [{ required: true, message: '请输入！' }] }]"
                placeholder="请输入保障服务内容"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  serviceAdd
} from '@/api/modular/mallLiving/securityServices'
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      pictureLoading:false,
      picture:'',
      cos: null,
    }
  },

  methods: {
    // 初始化方法
    add() {
      //下面是正常的初始化
      this.visible = true
      this.picture = '' //将图片重置为空
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            values.logo = this.picture
            delete values.picture
            serviceAdd(values).then((res) => {
              if(res.success){
                this.$message.success("新增成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    //自定义图片上传实现
    customUserRequest(filedata) {
      let that = this;
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
          }
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>